import {endDrawer} from '@/components/drawers';
import {ScrollCapture} from '@/hooks/use-scroll-capture';
import {logError} from '@/utilities/log';
import {effect} from '@preact/signals';
import {createFocusTrap} from 'focus-trap';

(() => {
  const cartDrawer = document.getElementById('cart-drawer');
  const cartDrawerClose = document.getElementById('cart-drawer-close');
  const cartDrawerScrim = document.getElementById('cart-drawer-scrim');

  if (!cartDrawer || !cartDrawerClose || !cartDrawerScrim) {
    logError(Error('Cart drawer elements not found'), {
      message: 'FailedInitializingCartDrawer',
    });
    return;
  }

  const trap = createFocusTrap(cartDrawer, {
    onDeactivate: closeDrawer,
  });

  const scrollCapture = new ScrollCapture();

  function closeDrawer() {
    endDrawer.close();
  }

  effect(() => {
    if (endDrawer.isOpen) {
      cartDrawer.style.setProperty('transform', 'translateX(0)');
      cartDrawerScrim.style.setProperty('opacity', '.6');
      cartDrawerScrim.style.setProperty('pointer-events', 'auto');
      cartDrawerScrim.addEventListener('mousedown', closeDrawer);
      trap.activate();
      scrollCapture.captureScroll();
    } else {
      cartDrawer.style.removeProperty('transform');
      cartDrawerScrim.style.removeProperty('opacity');
      cartDrawerScrim.style.removeProperty('pointer-events');
      cartDrawerScrim.removeEventListener('mousedown', closeDrawer);
      trap.deactivate();
      scrollCapture.releaseScroll();
    }
  });

  cartDrawerClose.addEventListener('click', closeDrawer);
})();
